<template>
  <db-select
    :model-value="brokerOrDepartmentId"
    collection="activeBrokers"
    @change="assignedChanged"
    @update:modelValue="assignedChanged"
    :additional-options="additionalBrokerOptions"
  />
</template>

<script>
export default {
  emits: ["update:departmentId", "update:brokerId", "change"],
  props: {
    departmentId: Number,
    brokerId: Number,
  },
  methods: {
    assignedChanged(newValue) {
      if (!newValue) {
        this.$emit("update:departmentId", null)
        this.$emit("update:brokerId", null)
      } else {
        if (typeof newValue === "string" && newValue.startsWith("dep-")) {
          this.$emit("update:departmentId", parseInt(newValue.replace("dep-", "")))
          this.$emit("update:brokerId", null)
        } else {
          this.$emit("update:departmentId", null)
          this.$emit("update:brokerId", newValue)
        }
      }
      this.$emit("change")
    },
  },
  computed: {
    brokerOrDepartmentId() {
      return this.brokerId || (this.departmentId ? `dep-${this.departmentId}` : null)
    },
    additionalBrokerOptions() {
      let departments = this.$db.shopData.departments.map(d => ({
        id: `dep-${d.id}`,
        type: "department",
        internalName: d.name,
      }))
      return departments
    },
  },
}
</script>
